.button-back{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
 
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    padding: 8px 16px 8px 0px;
    color: #ffff;
    font-weight: 600;
    line-height: 1.5rem;
    border-radius: 1000px;

    transition: .3s;
 }

 .button-back span {
    display: flex;
    margin-right: 14px;
    margin-left: 14px;
}


.button-back.back{
    background-color: var(--primary);
}

/* Hover */
.button-back.back:hover{
    background-color: var(--primary-dark);
}

/* Ativado */
.button-back.back:active{
    background-color: var(--primary-light);
}

/* Desativado */
.button-back.back:disabled{
   background-color: var(--primary);
   opacity: 40%;
}